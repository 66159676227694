.Logs {
    .icon {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        // background-color: rgba(247, 226, 233,100%);
        background-color: rgb(236 236 236);
        display: grid;
        place-items: center;
    }

    .avatar {
        height: 32px;
        width: 32px;
        display: grid;
        place-items: center;
        background-color: #d2e3eb;
        color: #1f272e;
        border-radius: 50%;
        font-weight: 500;
        font-size: clamp(0.6rem, 2vw, 0.75rem);
    }

    h5 {
        font-size: clamp(0.6rem, 1.1vw, 1rem);
        color: #777f85;
        font-weight: 400;
        width: calc(100% - 63px);
        word-break: break-all;
      p{display: inline;margin:0;}
        span {
            color: #1f272e;
            font-weight: 600;
            margin-inline: 6px;
        }
    }
    .content{width: calc(100% - 96px);}
    .time{
        font-size: clamp(0.6rem, 1.1vw, 1rem);
        color: #777f85;
        font-weight: 400;
        width:136px;
        text-align: right;
    }

    .Logs-card-body {
        overflow-y: auto;
        max-height: 264px;
        min-height: 18px;
        overflow-x: hidden;
    }

    .lines {
        position: relative;
        z-index: 1;
        padding-right: 5px;
        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 2px;
            background-color: #c5c7c9;
            display: block;
            left: 14px;
            bottom: -22px;
            z-index: -1;
        }
        &:last-child:before{
            display: none;
        }
    }
    .attachments{
        color: rgba(13, 110, 253,70%);
    }
    .delete{
        color: rgba(220, 53, 69,70%);
    }
    .assignees{
        color: rgba(108, 117, 125,70%);
    }
    .checklist{
        color:rgba(25, 135, 84,70%);    
    }
    .comment{
        color: rgba(255, 193, 7,70%);
    }
    .discription{
        color:rgba(13, 202, 240,70%);
    }
    .create{
        color: rgba(253, 126, 20,70%);
    }
}



@media only screen and (max-width: 600px) {

    .Logs{
        .avatar,.icon{height:26px;width:26px;}
    }
    .lines::before{left: 12px !important;bottom: -27px;}
    .time{width:71px !important;}
    .icon{
        svg{
            font-size: 10px;
        }
    }
    .content{width: calc(100% - 66px) !important;}
}