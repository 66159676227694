// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
// font-family: 'Roboto', sans-serif;

:root{
  --primary-bg:#F2F2F2;
  --slidebar-bg:#FBFCFA;
  --white-bg:#FFF; 
  --gray-bg-5:#E6E6E6;
  --gray-bg-6:#EBEBEB;
  --shadow-bg:rgba(66, 133, 244, 0.05);
  --primary-color:#1E58AE;

  --primary-btn:#4285F4;
  --primary-btn-hover:#185ABC;
  --sucess-btn:#34A853;
  --warring-btn:#EA4335;
  // --secondary-color:#f00;
  --primary-font:'Roboto', sans-serif;
  --secondary-font:'Roboto', sans-serif;
  --font-size-10x:10px;
  --font-size-11x:11px;
  --font-size-12x:12px;
  --font-size-13x:13px;
  --font-size-14x:14px;
  --font-size-16x:16px;
  --font-size-18x:18px;
  --font-size-20x:20px;
  --font-size-22x:22px;
  --font-size-24x:24px;

  
  --font-weight-4:400;
  --font-weight-5:500;
  --font-weight-6:600;
  --font-weight-7:700;
  --font-weight-9:900; 

  --br-radius6x:6px;
  --br-radius8x:8px;
  --br-radius12x:12px; 
  --br-radius18x:18px; 
  --br-radius24x:24px; 
  --br-radius100x:100px; 
  --br-radius100p:100%; 
}

body,html{min-height: 100%;}

body{
  margin: 0;
  padding: 0;
  font-family: var(--primary-font);
  line-height: 1.24;
  font-size: var(--font-size-14x);
  color:var(--color-primary); 
  background: var(--primary-bg) !important; 
}
.icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;
  border: none;
  box-shadow: none;
  height: 52px;
  width: 100%;
}

.settings-box {
  position: relative;
  background: var(--white);
  text-align: center;
  padding: 16px;
  margin: 8px;
  border-radius: 8px;
  border: 3px solid var( --shadow-bg);
  transition: all 0.3s;
  cursor: pointer;
}

